<template>
    <div>
        <form>
            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>First Name <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: Tammy">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Last Name <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: Smith">
                </div>
            </div>

            <div class="form-group">
                <div class="mb-4 ">
                    <label>Contact Title <span class="required-indc">*</span> </label>                
                    <input type="text" class="reg-field" placeholder="eg: HR Admin">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Email <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: email@email.com">
                </div>
                
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Mobile Phone Number <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: 123-123-123">
                </div>
            </div>           

            

            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>FAX Number</label>                
                    <input type="text" class="reg-field" placeholder="eg: 123-123-123">
                </div>
            </div>

            
            
        </form>
        <div class="action-btn split-action mt-5">
            <router-link to="/registration/group-info" class="button-black">Back</router-link>
            <div class="action-next">
                <router-link to="/registration/login-info" class="button-blue">Next</router-link>
                <router-link to="/registration/login-info" class="button-green">Save & Continue Late</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BrokerForm'
}
</script>